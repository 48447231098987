import React, { useState, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useOutsideClick from '../../hooks/useOutsideClick'
import { formatStringWithLink } from '../../utils/string'
import PostActorName from '../post/PostActorName'
import UserImage from '../profile/UserImage'
import More from '../Icons/More'
import { Gallery } from '../post/Gallery'
import { LikeButton } from '../post/LikeButton'
import PostMoreMenu from '../post/PostMoreMenu'

const Block = styled.div`
  display: flex;
  border-bottom: 1px solid #333;
  padding: 15px 0;
  position: relative;
  ${({ isDeletedUser }) =>
    isDeletedUser && 'opacity: 0.6; pointer-events: none;'}

  .user-image {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .comment-post {
    flex: 1;

    .link {
      display: block;
      padding-bottom: 5px;
      text-decoration: none;
    }

    &__text {
      color: white;
      font-size: 15px;
      line-height: 20px;
      margin-top: 3px;
      word-break: break-word;

      &--link {
        color: var(--theme-color);
        text-decoration: none;
      }
    }
  }

  .more {
    width: 40px;
    height: 40px;
    display: flex;
    opacity: 0.6;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-top: -14px;
    cursor: pointer;

    &:hover {
      background-color: rgb(51, 51, 51);
    }
  }

  .comment-post__image {
    margin-top: 10px;
    overflow: hidden;
  }

  .like-button-container {
    padding-top: 5px;
  }
`

export default function PostCommentBlock({ comment, activityId, objectId }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const menuRef = useRef()
  const moreIconRef = useRef()

  const user = comment?.user || {}
  const postComment = comment?.data || {}
  const images = comment?.attachments?.images || []

  useOutsideClick([menuRef, moreIconRef], () => setMenuOpen(false))

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const handleMentionClick = useCallback(
    (mention) => {
      navigate(`/${mention}`)
    },
    [navigate]
  )

  const isDeletedUser = !user.data?.name || !user.data?.username
  const userName = user.data?.name || 'Deleted User'
  const userUsername = user.data?.username || 'deleted'

  return (
    <Block isDeletedUser={isDeletedUser}>
      <div className="user-image">
        <UserImage
          src={user.data?.profile?.image || ''}
          alt={userName}
          username={userUsername}
        />
      </div>
      <div className="comment-post">
        <div>
          <PostActorName
            name={userName}
            username={userUsername}
            time={comment?.created_at || ''}
          />
          <div className="post__details">
            <p className="comment-post__text">
              {formatStringWithLink(
                postComment?.text || '',
                'post__text--link',
                false,
                handleMentionClick
              )}
            </p>
            {images.length > 0 && (
              <div className="comment-post__image">
                <Gallery images={images} />
              </div>
            )}
          </div>
          <div className="like-button-container">
            <LikeButton
              reaction={comment}
              activityId={activityId}
              objectId={objectId}
            />
          </div>
        </div>
      </div>
      <button className="more" onClick={toggleMenu} ref={moreIconRef}>
        <More size={18} color="white" />
      </button>
      {menuOpen && (
        <PostMoreMenu
          activity={comment}
          onClose={closeMenu}
          isComment
          ref={menuRef}
        />
      )}
    </Block>
  )
}
