import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AvatarGroup } from 'react-activity-feed'

import { getUsernameById } from '../../contexts/auth/AuthSlice'
import RelativeTime from '../date/RelativeTime'
import User from '../Icons/User'

const Block = styled.div`
  padding: 15px;
  border-bottom: 1px solid #333;
  display: flex;
  cursor: pointer;
  ${({ isDeletedUser }) =>
    isDeletedUser && 'pointer-events: none; opacity: 0.6;'}

  :hover {
    background-color: rgb(17, 17, 17);
  }

  .right {
    margin-left: 20px;
    flex: 1;
  }

  .right-group {
    display: flex;

    .raf-avatar-group {
      margin-right: 16px;
    }
  }

  .actors__text {
    margin-top: 10px;
    color: white;
    font-size: 15px;

    span {
      display: inline-block;
    }

    .actors__name {
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export default function FollowNotification({ followActivities }) {
  const navigate = useNavigate()

  const filterDuplicates = (activities) => {
    const uniqueActivities = new Map()
    activities.forEach((activity) => {
      const userId = activity.actor.id
      if (!uniqueActivities.has(userId)) {
        uniqueActivities.set(userId, {
          ...activity.actor,
          data: {
            ...activity.actor.data,
            profileImage: activity.actor.data?.profile?.image,
          },
        })
      }
    })
    return Array.from(uniqueActivities.values())
  }

  const uniqueFollowActivities = filterDuplicates(followActivities)
  const lastActivity = followActivities[0]
  const otherCount = uniqueFollowActivities.length - 1
  const time = lastActivity.time
  const isDeletedUser = !lastActivity.actor.data?.name || !lastActivity.actor.data?.username

  const getOtherFollowersText = (count) => {
    if (count > 0) {
      return (
        <span>
          and {count} other{count > 1 ? 's' : ' pilot'}
        </span>
      )
    }
    return null
  }

  const handleAvatarClick = (user) => {
    navigate(`/${user.data?.username}`)
  }

  const handleBlockClick = async () => {
    if (isDeletedUser) return

    try {
      const username = await getUsernameById(lastActivity.actor.id)
      if (username) {
        navigate(`/${username}`)
      }
    } catch (error) {
      console.error('Error fetching username:', error)
    }
  }

  return (
    <Block onClick={handleBlockClick} isDeletedUser={isDeletedUser}>
      <User color="#1c9bef" size={25} />
      <div className="right">
        <div className="right-group">
          <AvatarGroup
            users={uniqueFollowActivities}
            avatarSize={35}
            onClickUser={handleAvatarClick}
          />
          <RelativeTime date={time} />
        </div>
        <p className="actors__text">
          <span
            className="actors__name"
            onClick={(e) => {
              e.stopPropagation()
              if (!isDeletedUser) handleBlockClick()
            }}
          >
            {lastActivity.actor.data?.name || 'Deleted User'}
          </span>{' '}
          {getOtherFollowersText(otherCount)} followed you
        </p>
      </div>
    </Block>
  )
}
