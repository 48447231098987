import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useFeed } from '../../contexts/feed/useFeed'
import { useChat } from '../../contexts/chat/useChat'
import { updateUser, uploadImage } from '../../services/FeedService'
import {
  checkAvailability,
  updateProfile,
  uploadProfileImage,
} from '../../contexts/auth/AuthSlice'
import { Certifications } from './Certifications'
import { ProfileImageUpload } from './ProfileImageUpload'
import ProfileList from './ProfileList'
import ProfileInput from './ProfileInput'
import YearStarted from './YearStarted'

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 40px;
  width: 100%;
  color: #fff;
  overflow-y: auto;

  @media (min-width: 608px) {
    width: 600px;
  }
`

const ProfileHeader = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: black;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  height: 100px;

  &:focus {
    outline: 2px solid var(--theme-color);
  }
`

const ActionButton = styled.button`
  padding: 0.75rem 2rem;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.1s;
  width: 100%;
  background-color: var(--theme-color);
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;

  &:disabled {
    background-color: #555;
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const EditProfileView = ({ onSave }) => {
  const navigate = useNavigate()
  const { feedUser } = useFeed()
  const { chatClient } = useChat()

  const [profileData, setProfileData] = useState({
    id: feedUser?.data?.id || feedUser?.data?.id || '',
    username: feedUser?.data?.username || feedUser?.data?.username || '',
    name: feedUser?.data?.name || '',
    email: feedUser?.data?.email || '',
    profile: feedUser?.data?.profile || {
      bio: '',
      location: '',
      image: '',
      coverPhoto: '',
      yearStartedFlying: '',
      certifications: {
        p: '',
        h: '',
        s: '',
        t: '',
      },
      favoriteSites: [],
      wings: [],
      harnesses: [],
      inReachSocial: '',
      inReachEmail: '',
      xContestProfile: '',
      telegramUsername: '',
    },
  })

  const [selectedFile, setSelectedFile] = useState(null)
  const [imageSrc, setImageSrc] = useState(feedUser?.data?.profile?.image || '')
  const [coverFile, setCoverFile] = useState(null)
  const [coverImageSrc, setCoverImageSrc] = useState(
    feedUser?.data?.profile?.coverPhoto || ''
  )
  const [isLoading, setIsLoading] = useState(false)
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [usernameError, setUsernameError] = useState('')

  const USERNAME_REGEX = /^[a-z0-9_.-]{3,20}$/
  const MAX_NAME_LENGTH = 30

  const handleProfileChange = (event, field) => {
    setProfileData({
      ...profileData,
      profile: {
        ...profileData.profile,
        [field]: event.target.value,
      },
    })
  }

  const handleRootChange = (event, field) => {
    let value = event.target.value

    setNameError('')
    setUsernameError('')

    if (field === 'name' && value.length > MAX_NAME_LENGTH) {
      setNameError(`Display name cannot exceed ${MAX_NAME_LENGTH} characters.`)
      return
    }

    if (field === 'username') {
      value = value.toLowerCase()
    }

    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const handleImageChange = (file) => {
    setSelectedFile(file)
    setImageSrc(URL.createObjectURL(file))
  }

  const handleCoverImageChange = (file) => {
    setCoverFile(file)
    setCoverImageSrc(URL.createObjectURL(file))
  }

  const handleUpload = async (file) => {
    if (!file) return null
    try {
      const { name: fileName, type: mimeType } = file
      return uploadImage(
        fileName,
        mimeType,
        file,
        profileData.id,
        feedUser?.token || ''
      )
    } catch (error) {
      console.error('Error uploading image: ', error)
      return null
    }
  }

  const handleCertificationChange = (event, category) => {
    const value = event.target.value
    setProfileData((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        certifications: {
          ...prevState.profile.certifications,
          [category]: value,
        },
      },
    }))
  }

  const handleAvailabilityCheck = async (identifier, type) => {
    if (
      (type === 'email' && identifier !== feedUser?.data?.email) ||
      (type === 'username' && identifier !== feedUser?.data?.username)
    ) {
      try {
        const isAvailable = await checkAvailability(identifier)
        if (!isAvailable) {
          if (type === 'email') {
            setEmailError('Email is already in use')
          } else {
            setUsernameError('Username is already in use')
          }
        } else {
          if (type === 'email') {
            setEmailError('')
          } else {
            setUsernameError('')
          }
        }
        return isAvailable
      } catch (error) {
        console.error(`Error checking ${type} availability: `, error)
        if (type === 'email') {
          setEmailError('Email is already in use')
        } else {
          setUsernameError('Username is already in use')
        }
        return false
      }
    }
    return true
  }

  const handleSubmit = async () => {
    if (!profileData) {
      console.error('No user data to submit.')
      return
    }

    if (!profileData.name.trim()) {
      setNameError('Display name is required.')
      return
    }

    if (
      profileData.username.length < 3 ||
      !USERNAME_REGEX.test(profileData.username)
    ) {
      setUsernameError(
        'Username must be 3-20 characters and can include lowercase letters, numbers, underscores, hyphens, and periods.'
      )
      return
    }

    setIsLoading(true)

    try {
      let imageUrl = profileData.profile.image
      let coverPhotoUrl = profileData.profile.coverPhoto

      if (selectedFile) {
        imageUrl = await uploadProfileImage(
          { userId: profileData.id, file: selectedFile },
          profileData.feedToken
        )
      }

      if (coverFile) {
        coverPhotoUrl = await handleUpload(coverFile)
      }

      const updatedUserData = {
        ...profileData,
        profile: {
          ...profileData.profile,
          image: imageUrl,
          coverPhoto: coverPhotoUrl,
        },
      }

      const updatedUser = await updateUser(
        updatedUserData,
        profileData.id,
        feedUser?.token || ''
      )

      if (chatClient && profileData.id) {
        await chatClient.upsertUser({
          id: profileData.id,
          ...updatedUserData,
        })
      } else {
        console.error('Chat client is not initialized or userId is undefined.')
      }

      await updateProfile(updatedUserData, feedUser?.token || '')

      onSave(updatedUser)
    } catch (error) {
      console.error('Error updating user: ', error)
    } finally {
      setIsLoading(false)
      navigate(`/${profileData.username}`)
      window.location.reload()
    }
  }

  return (
    <ProfileContainer>
      <form style={{ width: '100%' }} onSubmit={(e) => e.preventDefault()}>
        <ModalHeader>
          <ProfileHeader>Edit profile</ProfileHeader>
        </ModalHeader>
        <ProfileImageUpload
          imageSrc={imageSrc}
          coverImageSrc={coverImageSrc}
          onImageChange={handleImageChange}
          onCoverImageChange={handleCoverImageChange}
        />
        <ProfileInput
          style={{ marginTop: '80px' }}
          label="Display Name"
          value={profileData.name}
          onChange={(e) => handleRootChange(e, 'name')}
          placeholder="Name"
        />
        {nameError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>{nameError}</div>
        )}
        <ProfileInput
          label="Username"
          value={profileData.username}
          onChange={(e) => handleRootChange(e, 'username')}
          placeholder="username"
          onBlur={(e) => handleAvailabilityCheck(e.target.value, 'username')}
        />
        {usernameError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {usernameError}
          </div>
        )}
        <ProfileInput
          value={profileData.email}
          onChange={(e) => handleRootChange(e, 'email')}
          placeholder="Email"
          onBlur={(e) => handleAvailabilityCheck(e.target.value, 'email')}
        />
        {emailError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>{emailError}</div>
        )}
        <ProfileInput
          value={profileData.profile?.location}
          onChange={(e) => handleProfileChange(e, 'location')}
          placeholder="Location"
        />
        <TextArea
          id="bio"
          name="bio"
          value={profileData.profile?.bio}
          onChange={(e) => handleProfileChange(e, 'bio')}
          placeholder="Bio"
        />
        <YearStarted
          value={profileData.profile.yearStartedFlying}
          onChange={(e) => handleProfileChange(e, 'yearStartedFlying')}
        />
        <Certifications
          certifications={profileData.profile.certifications}
          handleCertificationChange={handleCertificationChange}
        />
        <ProfileList
          label="Favorite Flying Sites"
          items={profileData.profile.favoriteSites}
          setItems={(favoriteSites) =>
            setProfileData({
              ...profileData,
              profile: {
                ...profileData.profile,
                favoriteSites,
              },
            })
          }
          placeholder="Favorite Flying Site"
        />
        <ProfileList
          label="Gear"
          items={profileData.profile.wings}
          setItems={(wings) =>
            setProfileData({
              ...profileData,
              profile: {
                ...profileData.profile,
                wings,
              },
            })
          }
          placeholder="Wing"
        />
        <ProfileList
          items={profileData.profile.harnesses}
          setItems={(harnesses) =>
            setProfileData({
              ...profileData,
              profile: {
                ...profileData.profile,
                harnesses,
              },
            })
          }
          placeholder="Harness"
        />
        <ProfileInput
          label="Contacts"
          value={profileData.profile.inReachSocial || ''}
          onChange={(e) => handleProfileChange(e, 'inReachSocial')}
          placeholder="https://share.garmin.com/your_name"
        />
        <ProfileInput
          value={profileData.profile.inReachEmail || ''}
          onChange={(e) => handleProfileChange(e, 'inReachEmail')}
          placeholder="johndoe@inreach.garmin.com"
        />
        <ProfileInput
          value={profileData.profile.xContestProfile || ''}
          onChange={(e) => handleProfileChange(e, 'xContestProfile')}
          placeholder="https://www.xcontest.org/world/en/pilots/detail:your_name"
        />
        <ProfileInput
          value={profileData.profile.telegramUsername || ''}
          onChange={(e) => handleProfileChange(e, 'telegramUsername')}
          placeholder="Telegram Username"
        />
        {(nameError || emailError || usernameError) && (
          <div style={{ color: 'red' }}>
            Please fix any errors before saving.
          </div>
        )}
        <ActionButton
          onClick={handleSubmit}
          disabled={isLoading || nameError || usernameError || emailError}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </ActionButton>
      </form>
    </ProfileContainer>
  )
}
