import React from 'react'
import { useStreamContext, AvatarGroup } from 'react-activity-feed'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { generatePostLink } from '../../utils/links'
import RelativeTime from '../date/RelativeTime'
import CommentIcon from '../Icons/Comment'

const Block = styled.button`
  padding: 15px;
  border-bottom: 1px solid #333;
  display: flex;
  ${({ isDeletedUser }) =>
    isDeletedUser && 'pointer-events: none; opacity: 0.6;'}

  :hover {
    background-color: rgb(17, 17, 17);
  }

  a {
    color: white;
  }

  span {
    display: inline-block;
  }

  .right {
    margin-left: 20px;
    flex: 1;
  }

  .right-group {
    display: flex;

    .raf-avatar-group {
      margin-right: 16px;
    }
  }

  .comment-actors__text {
    margin-top: 10px;
    color: white;
    font-size: 15px;

    .comment-actor__name {
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .post-text {
    display: block;
    color: #888;
    margin-top: 10px;
  }
`

export default function CommentNotification({ commentActivities }) {
  const commentGroup = {}
  const navigate = useNavigate()
  const { user } = useStreamContext()

  commentActivities.forEach((cAct) => {
    const parentId = cAct.data?.parentActivityId
    if (parentId in commentGroup) {
      commentGroup[parentId].push(cAct)
    } else {
      commentGroup[parentId] = [cAct]
    }
  })

  const getOtherCommentsText = (count) => {
    if (count > 0) {
      return `and ${count} other${count > 1 ? 's' : ' person'}`
    }
    return ''
  }

  const handleAvatarClick = (clickedUser) => {
    navigate(`/${clickedUser.data?.username}`)
  }

  return (
    <>
      {Object.keys(commentGroup).map((groupKey) => {
        const activities = commentGroup[groupKey]

        const uniqueUsers = new Map()
        activities.forEach((activity) => {
          uniqueUsers.set(activity.actor.id, {
            ...activity.actor,
            data: {
              ...activity.actor.data,
              profileImage: activity.actor.data?.profile?.image,
            },
          })
        })

        const uniqueUserList = Array.from(uniqueUsers.values())
        const otherCount = uniqueUserList.length - 1
        const lastActivity = activities[0]
        const time = new Date(lastActivity.time)

        const isDeletedUser =
          !lastActivity.actor.data?.name || !lastActivity.actor.data?.username

        const postLink = generatePostLink(
          user.data?.username,
          lastActivity.data?.parentActivityId
        )

        return (
          <Block
            className="active"
            onClick={() => navigate(postLink)}
            key={groupKey}
            isDeletedUser={isDeletedUser}
          >
            <CommentIcon color="var(--theme-color)" size={25} />
            <div className="right">
              <div className="right-group">
                <AvatarGroup
                  users={uniqueUserList}
                  avatarSize={35}
                  onClickUser={handleAvatarClick}
                />
                <RelativeTime date={time} />
              </div>
              <div className="comment-actors__text">
                <span
                  className="comment-actor__name"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/${lastActivity.actor.data?.username}`)
                  }}
                >
                  {lastActivity.actor.data?.name || 'Deleted User'}
                </span>{' '}
                <span>
                  {getOtherCommentsText(otherCount)} commented on your post
                </span>
              </div>
              <p className="post-text">
                {lastActivity.object.data?.text || ''}
              </p>
            </div>
          </Block>
        )
      })}
    </>
  )
}
